/* Customize the scrollbar in WebKit-based browsers (Chrome, Safari) */

/* This style is setting the height of the scrollbar track */
::-webkit-scrollbar {
    height: 5px !important;
}

/* This style is setting the width, height, and border-radius of the scrollbar thumb */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 3px;
}

/* This style is setting the background and border-radius of the scrollbar thumb */
::-webkit-scrollbar-thumb {
    background: #c7c7c7;
    border-radius: 3px;
}

h1,
.h1 {
    font-size: 4.3vh;
    line-height: 5.3vh;
}

h4,
.h4 {
    font-size: 2.55vh;
}

.b-0 {
    border: 0 !important;
}

hr {
    border-color: #838383;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mt-40 {
    margin-top: 40px;
}

a {
    font-size: 1.73vh !important;
}

p {
    font-size: 1.72vh;
    line-height: 2.72vh;
    margin-bottom: 2vh;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: var(--green);
}

.dropdown-item {
    padding: 1.1vh 1.5vh;
}

.nav-link.active:before {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: var(--green);
    margin: auto;
    width: 2vh;
}

.nav-link {
    position: relative;
}

.navbar {
    padding: 1.1vh;
}

@font-face {
    font-family: metropolis;
    src: url(../font/metropolis-regular.otf);
}

@font-face {
    font-family: Gilroy-Regular;
    src: url(../font/Gilroy-Regular.ttf);
}

@font-face {
    font-family: Gilroy-RegularItalic;
    src: url(../font/Gilroy-RegularItalic.ttf);
}

@font-face {
    font-family: Gilroy-Medium;
    src: url(../font/Gilroy-Medium.ttf);
}

@font-face {
    font-family: Gilroy-SemiBold;
    src: url(../font/Gilroy-SemiBold.ttf);
}

@font-face {
    font-family: Gilroy-Bold;
    src: url(../font/Gilroy-Bold.ttf);
}

@font-face {
    font-family: DMSerifDisplay;
    src: url(../font/DMSerifDisplay.ttf);
}

@font-face {
    font-family: caveat;
    src: url(../font/caveat.ttf);
}

body {
    height: 100vh;
    overflow: hidden;
    color: var(--black);
    font-family: var(--b-font);
}

.home-top h1 {
    font-size: 5vh;
    line-height: 5vh;
    z-index: 1;
}

h1 {
    font-family: var(--h-font);
}

.cust-underline {
    position: relative;
    display: block;
}

    .cust-underline:after {
        position: absolute;
        content: '';
        background-image: url(../images/underline.png);
        width: 26vh;
        height: 6.3vh;
        background-size: 100%;
        left: 0px;
        top: 10vh;
        background-repeat: no-repeat;
        z-index: -1;
    }


.home-top .tagline {
    margin: unset !important;
}

:root {
    --h-font: "DMSerifDisplay" !important;
    --b-font: "metropolis" !important;
    --b2-font: "Gilroy-Medium" !important;
    --text-field: #F4F4F4 !important;
    --black: #242424 !important;
    --green: #7fb432 !important;
    --orange: #F7B70E !important;
    --white: #ffffff !important;
    --dark-green: #001d23 !important;
}

.framestyle iframe {
    display: block;
    width: 100%;
    height: 100%;
}

.framestyle {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 9999;
}

.closeBttn {
    position: absolute;
    margin: 0;
    bottom: 20px;
    right: 40px;
    z-index: 999999;
}

.nav-link {
    color: var(--black);
    font-family: var(--b2-font);
}

.nav-link {
    padding: 1.1vh 1.5vh !important;
}

.bg-light {
    background-color: transparent !important;
}

.logo img {
    width: 16vh;
}

.btn {
    padding: 1.1vh 4.3vh;
    border-top-right-radius: 3vh;
    border-bottom-right-radius: 3vh;
    border-bottom-left-radius: 3vh;
    border-top-left-radius: 0;
    font-size: 1.73vh !important;
}

.btn-primary {
    background: var(--green);
    border-color: var(--green);
    color: var(--white);
    width: fit-content;
}

.new-page {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.d-none {
    display: none;
}

.home-tab {
    display: flex;
    justify-content: space-between;
    overflow-x: auto;
    border-bottom: 0;
    width: 100%;
    /* Allow horizontal scrolling if the buttons overflow the container */
}

    .home-tab .nav-link.active:before {
        content: none !important;
    }

    .home-tab li {
        flex: 1 0 150px;
        padding: 0 12px;
        overflow: hidden;
    }

    .home-tab button {
        text-align: left;
        word-break: break-word;
        white-space: normal;
        /* Allow the text to wrap if it's too long */
    }

    .home-tab .nav-link img {
        height: 5.5vh;
        margin-bottom: 10px;
    }

    .home-tab.nav-tabs .nav-link.active,
    .home-tab.nav-tabs .nav-item.show .nav-link {
        background: var(--green);
        color: var(--white);
        margin: 0;
        padding: 0;
        border-radius: 20px;
    }

    .home-tab p {
        margin-bottom: 1vh;
    }

    .home-tab.nav-tabs .nav-link.active h4,
    .home-tab.nav-tabs .nav-link.active p {
        color: var(--white) !important;
    }

    .home-tab.nav-tabs .nav-link {
        border: 0 !important;
        color: var(--black);
        margin: 0;
        padding: 0;
        width: 100%;
    }

        .home-tab.nav-tabs .nav-link:hover,
        .home-tab.nav-tabs .nav-link:focus {
            border: 0 !important;
            color: var(--black);
            margin: 0;
            padding: 0;
            border-radius: 20px;
        }

        .home-tab.nav-tabs .nav-link:hover {
            background: #f2f2f2;
            border-radius: 20px;
        }

        .home-tab.nav-tabs .nav-link.active:hover {
            background: var(--green);
        }

.home-top {
    /* height: 65vh; */
    height: 77vh;
    flex: 1 1;
    display: flex;
    align-items: center;
}

.btn-outline {
    border: 2px solid;
    border-color: var(--green);
    color: var(--green);
    width: fit-content;
    font-weight: 600;
}

/* .chart canvas {
     min-width: 300px;
     max-width: 300px;
} */

.chart-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.chart.col-30 {
    flex-direction: column;
    align-items: center;
}

.chart ul {
    border: 2px solid;
    border-color: var(--green);
    border-top-left-radius: 0;
    border-top-right-radius: 25px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    overflow: hidden;
    width: fit-content;
    margin: auto;
}

.chart .nav-tabs .nav-link {
    padding: 1.1vh 4vh !important;
    font-size: 1.73vh !important;
}

    .chart .nav-tabs .nav-link:hover,
    .chart .nav-tabs .nav-link {
        color: var(--green);
    }

        .chart .nav-tabs .nav-link.active,
        .chart .nav-tabs .nav-item.show .nav-link {
            background: var(--green);
            color: var(--white);
            border-top-left-radius: 0;
            border-top-right-radius: 25px;
            border-bottom-left-radius: 25px;
            border-bottom-right-radius: 25px;
            border-color: var(--green);
        }

.home-top .col-30 {
    width: 100%;
    max-width: 30%;
    flex: 1 1;
    display: flex;
    align-items: center;
    height: inherit;
}

.home-top .col-40 {
    width: 100%;
    max-width: 40%;
    flex: 1 1;
    display: flex;
    align-items: center;
}

    .home-top .col-40 img {
        max-width: 75%;
        max-height: 75%;
        /* max-width: 100%;
        max-height: 100%; */
        display: block;
        margin: auto;
    }

.btn-outline:hover {
    border: 2px solid;
    border-color: var(--green) !important;
    color: var(--white) !important;
    width: fit-content;
    background-color: var(--green) !important;
    font-weight: 600;
}

.btn-outline:active,
.btn-outline:focus {
    border: 2px solid;
    border-color: var(--green) !important;
    color: var(--green) !important;
    width: fit-content;
    background-color: var(--white) !important;
    font-weight: 600;
}

.home-bottom {
    /* height: 25vh; */
    height: 23vh;
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header,
.admin-header {
    height: 10vh;
    /* height: 10.8vh; */
    /* Vertically center the text within the header */
    position: fixed;
    /* Fixed position for header */
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    /* Ensure header is above other elements */
}

.footer {
    background: var(--dark-green);
    color: var(--white);
    height: 5.4vh;
    text-align: center;
    line-height: 5.4vh;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
}

.voluntter-img {
    max-width: 60vh;
    width: 100%;
    margin: auto;
}

.voluntter-img {
    max-width: 60vh;
    width: 100%;
    margin: auto;
}

.register-form {
    width: 100%;
    max-width: 70%;
    margin: auto;
}

.form-reg {
    /* height: 65vh; */
    max-height: 65vh;
    overflow: auto;
    overflow-y: 'scroll'
}

#root {
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.login {
    flex: 1;
    /* This will make the body take the remaining vertical space */
    display: flex;
}

.footer p {
    justify-content: center;
    display: flex;
    text-align: center;
    line-height: 5.5vh;
}

    .footer p a {
        padding: 0 !important;
        color: var(--green);
        margin-left: 5px;
        margin-right: 5px;
    }

.p-0 {
    padding: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.p-10 {
    padding: 1.1vh !important;
}


.m-0 {
    margin: 0 !important;
}

.m-auto {
    margin: auto;
}

.w-100 {
    width: 100%;
}

.mw-100 {
    max-width: 100%;
}

label {
    font-size: 1.74vh;
}

.mb-15 {
    margin-bottom: 1.5vh;
}

.mb-20 {
    margin-bottom: 2vh;
}

.mb-30 {
    margin-bottom: 3vh;
}

.ml-5 {
    margin-left: 5px !important;
}

.mr-20 {
    margin-right: 2vh !important;
}

.mr-10 {
    margin-right: 1.1vh !important;
}

img.social-media {
    width: 5.4vh;
}

.middle-image {
    position: absolute;
    top: 0;
    height: 80%;
    width: auto;
    margin: auto;
    left: 24vw;
    right: 42vw;
}

.bg-left {
    flex: 1;
    /* Take 50% width */
    background-color: #cccccc;
    position: relative;
    /* To allow positioning of background image */
    max-width: 41.66666667%;
    min-width: 41.66666667%;
    width: 100%;
}

.bg-left2 {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

img.contact-image {
    width: 1.5vh;
}

.card-body {
    position: relative;
}

.btn-tertiary {
    background: var(--green);
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 4.3vh;
    padding: 1.5vh 1.5vh 1.5vh 2vh;
    position: absolute;
    right: 0;
    bottom: 0;
}

    .btn-tertiary:hover,
    .btn-tertiary:active,
    .btn-tertiary:focus {
        background: var(--green);
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 0;
        border-top-left-radius: 4.3vh;
        padding: 1.5vh 1.5vh 1.5vh 2vh;
    }

.bg-right {
    flex: 1;
    /* Take 50% width */
    display: flex;
    justify-content: center;
    /* Horizontally center the content */
    align-items: center;
    /* Vertically center the content */
    max-width: 58.33333333%;
    min-width: 58.33333333%;
    width: 100%;
}

.about-content {
    flex: 1 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    min-width: 58.33333333%;
}

.about-mission {
    flex: 1 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    min-width: 30%;
    width: 30%;
    max-width: 30%;
}

.about-content .tagline {
    margin: unset !important;
}

.about-logo {
    border-right: 1px solid #B3B3B3;
}

.about-container {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 41.66666667%;
}

.mb-10 {
    margin-bottom: 1.1vh !important;
}

.about-mission p {
    border-left: 3px solid var(--green);
    padding-left: 1.5vh;
}

.about-img {
    max-width: 45vh;
}

.about-mission .mission-img {
    width: 4.3vh;
}

.ptb-15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.mt-10 {
    margin-top: 1.1vh;
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px;
}

.bg-top {
    height: 70vh;
    background: url('../images/bg-abstract.png');
    flex: 1;
    /* Take 50% width */
    display: flex;
    justify-content: center;
    /* Horizontally center the content */
    align-items: center;
}

.bg-bottom {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30vh;
    background-color: var(--dark-green);
    color: var(--white);
}

.tagline {
    position: relative;
    display: block;
    font-family: caveat;
    font-size: 3vh;
    color: var(--green);
    line-height: 4vh;
    font-weight: 400;
    padding-bottom: 0.6vh;
    margin-bottom: 0.6vh;
    margin: auto;
}

    .tagline:before {
        position: absolute;
        content: '';
        background-image: url(../images/divider.png);
        width: 8.6vh;
        height: 0.6vh;
        left: 0px;
        bottom: 0px;
        background-repeat: no-repeat;
    }

.cust-link {
    color: var(--green);
}

.donation {
    flex: 1 1;
    display: flex;
    background: #F7F8F9;
}

.donation-form {
    width: 100%;
    max-width: 50%;
    height: auto;
    max-height: 85vh;
    overflow: hidden;
    overflow-y: scroll;
}

.donation-left {
    max-width: 35% !important;
    min-width: 35% !important;
}

.donation-right {
    max-width: 60% !important;
    min-width: 60% !important;
}

.bottom-image2 {
    position: absolute;
    bottom: 0;
    left: 0;
    /* transform: translateX(-50%); */
    width: 73vh;
}

.form-control {
    background: var(--text-field);
    height: auto;
    padding: 1.5vh;
    border: 0;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 50px !important;
    border-bottom-left-radius: 50px !important;
    border-bottom-right-radius: 50px !important;
    margin-bottom: 1.5vh;
}

@media (min-width: 1200px) {

    h3,
    .h3 {
        font-size: 1.75rem;
        font-size: 3.05vh;
    }
}

.centered-h2 {
    position: relative;
    margin: 20vh 0 0 0;
    font-family: var(--h-font);
    color: var(--black);
    padding-left: 10%;
    padding-right: 10%;
    z-index: 1;
    margin-bottom: 1.1vh;
    text-align: left;
}

.centered-p {
    padding-left: 10%;
    padding-right: 10%;
    text-align: right;
}

/* Positioned Relative Image at the Bottom */
.bottom-image {
    position: absolute;
    bottom: 5.2vh;
    left: 50%;
    transform: translateX(-50%);
}

.d-grid {
    display: grid;
}

.background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(../images/bg-1.png);
    /* Add the path to your background image */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.new-tf textarea {
    min-height: 30vh;
}

.new-tf textarea {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 25px !important;
    border-bottom-left-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
}

.events,
.events-single,
.solution-page {
    padding-top: 10.8vh;
    /* background: #F7F8F9; */
    background: #FFFFFF;
    height: 100vh;
}

.cust-dashboard {
    background: #F7F8F9;
    height: 90vh;
}

.cust-content {
    margin-top: 10.8vh;
    background: #ffffff;
    height: 90vh;
    z-index: 1;
    position: relative;
    border-radius: 15px;
}

.home-page {
    height: 100vh;
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact {
    background: #ffffff;
    height: 100vh;
}

.banner img {
    width: 100%;
}

.res-container {
    width: 100%;
    margin: auto;
    padding: 0 1.1vh;
}

.cust-txt {
    align-content: center;
    flex-wrap: wrap;
    display: flex;
}

.head {
    font-family: var(--h-font);
}

.jc-sb {
    justify-content: space-between;
}

.date-p {
    display: flex;
    justify-content: center;
}

.row {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.p-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.events .card-header {
    border: 0 !important;
    padding: 0 !important;
    border-radius: 0 !important;
}

.solution-page.events .card-header {
    max-height: 180px;
}

.float-right {
    float: right;
}

.event-head {
    background: #ffffff;
    border: 1px solid #E5E5E5;
    border-top-left-radius: 0;
    border-top-right-radius: 25px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 0;
    padding: 5px 0 0 0;
}

.solution-page.events ul.nav {
    border-bottom: 1px solid #ebebeb !important;
}

.solution-page.events .card-header .overlay .cust-time,
.solution-page.events .card-header .overlay .cust-location {
    padding: 8px;
}

.p-15 {
    padding: 1.5vh;
}

.p-20 {
    padding: 2vh !important;
}

.p-5 {
    padding: 5px !important;
}

.p-10 {
    padding: 1.1vh !important;
}

.plr-15 {
    padding-left: 1.5vh !important;
    padding-right: 1.5vh !important;
}

.plr-20 {
    padding-left: 2vh !important;
    padding-right: 2vh !important;
}

.plr-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.plr-10 {
    padding-left: 1.1vh !important;
    padding-right: 1.1vh !important;
}

.events .nav-tabs .nav-link.active {
    border: none !important;
    margin: 0;
    color: var(--green) !important;
    padding: 1.1vh !important;
    border-radius: 0;
    outline: 0;
}

.events ul.nav {
    border: none !important;
}

.events .nav-tabs .nav-link:hover,
.events .nav-tabs .nav-link:focus {
    border: none !important;
}

.events .nav-tabs .nav-link:hover,
.events .nav-tabs .nav-link:focus,
.events .nav-tabs .nav-link:visited {
    border: none !important;
    margin: 0;
    color: var(--black);
    padding: 1.1vh !important;
    border-radius: 0;
    outline: 0;
}

.events .nav-tabs .nav-link {
    border: none !important;
    margin: 0;
    color: var(--black);
    padding: 1.1vh !important;
    border-radius: 0;
    outline: 0;
}

.events .nav-link.active:after {
    width: 100% !important;
}

.events .card {
    border: 1px solid #E5E5E5;
    border-top-left-radius: 0;
    border-top-right-radius: 4.3vh;
    border-bottom-left-radius: 4.3vh;
    border-bottom-right-radius: 0;
    /* overflow: hidden; */
}

.events .card-body {
    padding: 1.5vh 55px 0px 2vh;
}

.events .card-title {
    font-family: var(--h-font);
}

.events .card-header {
    position: relative;
    border-bottom-left-radius: 4.3vh !important;
    /* overflow: hidden; */
}

.small-blog-img {
    border-top-right-radius: 30px;
    border-bottom-left-radius: 30px;
    overflow: hidden;
}

.events .card-header .overlay {
    position: absolute;
    left: 0;
    right: 0;
    height: 9vh;
    width: 100%;
    bottom: 0;
    background: url('../images/mesh.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.events .card-header .overlay2 {
    position: absolute;
    left: 0;
    right: 0;
    height: 5px;
    width: 65%;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    margin: auto;
    bottom: 0px;
    background-color: var(--orange);
    z-index: 2;
}

.events .card-header .overlay .row {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    bottom: 0;
}

.events .card-header .overlay p span:nth-child(1) {
    padding: 12px 1.1vh;
}

.events .card-header .overlay p span:nth-child(3) {
    padding: 12px 1.1vh;
}

.events .card-header .overlay .cust-time,
.events .card-header .overlay .cust-location {
    padding: 16px;
    margin-bottom: 0;
}

.mr-5 {
    margin-right: 5px !important;
}

.events .overlay .row {
    /* opacity: 0.31; */
    color: var(--white);
}

    /* .events .overlay .row .col:nth-child(2) {
     border-left: 2px solid #ffffff61;
     border-right: 2px solid #ffffff61;
} */

    .events .overlay .row .col img {
        width: 1.5vh;
    }

/* Custom carousel indicators (circles) */
.custom-indicators li {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    width: 8px;
    height: 8px;
    margin: 0 4px;
}

    .custom-indicators li.active {
        background-color: rgba(0, 0, 0, 0.7);
    }

.carousel-indicators {
    align-items: center;
    bottom: -3vh;
}

    .carousel-indicators [data-bs-target] {
        width: 1.1vh;
        height: 1.1vh;
        background-color: #b7b7b7;
    }

.custom-indicators li.active {
    background-color: var(--green);
    width: 1.5vh;
    height: 1.5vh;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    filter: brightness(0) saturate(100%) invert(58%) sepia(74%) saturate(413%) hue-rotate(42deg) brightness(96%) contrast(84%);
}

.carousel-control-prev {
    left: -70px;
}

.carousel-control-next {
    right: -70px;
}

.carousel-control-prev,
.carousel-control-next {
    opacity: 1;
    width: 5%;
}

.c-card {
    background: #ffffff;
    border: 0;
    padding: 2vh;
    border-top-left-radius: 0;
    border-top-right-radius: 4.3vh;
    border-bottom-left-radius: 4.3vh;
    border-bottom-right-radius: 0;
}

    .c-card .card-header {
        background: #ffffff;
        border-bottom: 1px solid #E5E5E5;
        padding: 0px;
    }

    .c-card .card-title {
        margin-bottom: 1.5vh;
    }

    .c-card .card-body {
        padding: 2vh 0 0 0;
    }

.box {
    border: 0;
    padding: 1.5vh;
    background: var(--green);
    color: var(--white);
    border-top-left-radius: 0;
    border-top-right-radius: 4.3vh;
    border-bottom-left-radius: 4.3vh;
    border-bottom-right-radius: 0;
}

    .box .c-icons {
        width: 17px;
        height: auto;
    }

.mb-5 {
    margin-bottom: 5px !important;
}

/* ContactForm.css */
.form-group {
    position: relative;
    margin-bottom: 2.5vh;
}

.tooltip {
    position: absolute;
    top: -3vh;
    left: 0;
    background-color: #f44336;
    color: #fff;
    padding: 5px 1.1vh;
    border-radius: 4px;
    font-size: 14px;
}

    .tooltip::before {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        border-width: 5px;
        border-style: solid;
        border-color: #f44336 transparent transparent transparent;
        transform: translateX(-50%);
    }

.react-datepicker__close-icon::after {
    background-color: var(--green);
}

.react-datepicker__close-icon {
    padding: 0 15px 0 0;
}

.pt-10 {
    padding-top: 10px;
}

.react-datepicker-wrapper {
    width: 100%;
}

.mr-30 {
    margin-right: 30px !important;
}

.tooltip::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    border-width: 4px;
    border-style: solid;
    border-color: #f44336 transparent transparent transparent;
    transform: translateX(-50%);
}

button#interestedInDropdown {
    width: 100%;
    text-align: left;
    padding: 12px 1.5vh 12px 1.5vh;
    background: #f4f4f4;
    color: #79757d;
    border: 0;
    overflow: hidden;
}

.single-banner {
    width: 100%;
    height: 35vh;
    margin-bottom: 20px;
    background-size: cover;
    background-position: center center;
    text-align: center;
    overflow: hidden;
    border-top-right-radius: 4.3vh;
    border-bottom-left-radius: 4.3vh;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
}

.event-img2 {
    width: 100%;
    height: 22vh;
    background-size: cover !important;
    background-position: center center !important;
}

.red {
    color: red;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.contact-form {
    width: 100%;
    max-width: 70%;
    margin: auto;
}

.m-auto {
    margin: auto;
}

.p-relative {
    position: relative;
}

.h-100 {
    height: 100%;
}

/* .cust-home-banner {
    position: absolute;
    height: 70vh;
    width: auto !important;
    top: 40px;
    bottom: 0px;
    left: 0;
    margin: auto;
    right: 0;
} */

.home-page {
    background: url('../images/home2.png');
    background-size: 100%;
    background-repeat: no-repeat;
}

.chart-container li {
    display: flex;
    font-size: 1.3vh;
    /* flex: 1; */
    flex: 1 1;
    display: flex;
    justify-content: left;
    align-items: baseline;
    padding-bottom: 1.1vh;
}

    .chart-container li span {
        width: 7px;
        height: 7px;
        display: block;
        border-radius: 5px;
        margin-right: 5px;
    }

.chart-container ul {
    column-count: 2;
    border: 0;
    margin-top: 15px;
    padding-left: 0;
}

.chart-container.cc-5 ul {
    column-count: 5 !important;
}

.modal-header {
    background: var(--green);
    color: var(--white);
    padding: 10px 20px 10px 20px;
    border-radius: 0;
}

.modal-content {
    border-top-right-radius: 30px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
    overflow: hidden;
}

.modal-header button.close {
    border: 0;
    background: var(--green);
    color: #ffffff;
    font-size: 30px;
    padding: 0 10px;
}

.modal {
    backdrop-filter: blur(20px);
}

.customer-navbar {
    background-image: url("../images/portal-banner.png");
    background-size: cover;
    height: 20vh;
    display: flex;
    align-items: start;
    position: absolute;
    width: 100%;
}

.donation .row {
    background: url("../images/bg-abstract.png");
}

.donation .bg-left {
    background: none;
}

.customer-navbar .nav-link {
    color: #fff !important;
    /* Nav link text color */
}

    .customer-navbar .nav-link:hover {
        color: #ccc;
        /* Nav link hover text color */
    }

.profile-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
}

/* Profile Modal styles */
.profile-modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    /* Modal overlay background color */
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-dialog-slideout-right {
    position: fixed;
    top: 0;
    right: -400px;
    /* Width of the modal */
    height: 100%;
    margin: 0;
    overflow-y: auto;
    transition: right 0.3s ease-out;
}

.modal.show .modal-dialog-slideout-right {
    right: 0;
}

.sm-right {
    position: absolute;
    top: 20vh;
    right: 1vw;
    bottom: 0;
}

    .sm-right a {
        margin-bottom: 20px;
    }

.close {
    /* Add styles for the close button here */
    font-size: 1.5rem;
    font-weight: 700;
    color: #fff;
    opacity: 1;
    transition: opacity 0.2s;
}

@keyframes slideInRight {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}


.pm.modal {
    backdrop-filter: none;
}

.pm .modal-dialog {
    width: 400px;
    box-shadow: rgba(0, 0, 0, 0.1) -3px 4px 14px 0px;
    height: 100vh;
    border-bottom-left-radius: 40px;
}

.pm .modal-header {
    background: #fff;
    color: var(--black);
}

    .pm .modal-header button.close {
        border: 0;
        background: var(--white);
        color: var(--black);
        font-size: 30px;
        padding: 0 10px;
    }

.pm .modal-content {
    border: 0;
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-radius: 0;
}

.white {
    color: var(--white);
}

.green {
    color: var(--green)
}

.profile-update {
    border-radius: 50%;
}

.profile-main {
    flex: 1 1;
    display: flex;
    justify-content: left;
    align-items: baseline;
}

.profile-close {
    color: var(--black);
    border: 0;
    background: transparent;
}

.btn:hover {
    color: var(--bs-btn-hover-color);
    background-color: var(--green);
    border-color: var(--green);
}

.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
    color: var(--bs-btn-active-color);
    background-color: var(--green);
    border-color: var(--green);
}

.arrow {
    position: absolute;
    bottom: 4vh;
    left: 0;
    width: 22px;
    height: 124px;
    transform: translate(-50%, -50%);
    transform: rotate(90deg);
    cursor: pointer;
}

    .arrow span {
        display: block;
        width: 1vw;
        height: 1vw;
        border-bottom: 3px solid #7fb432;
        border-right: 3px solid #7fb432;
        transform: rotate(45deg);
        margin: -3px;
        animation: animate 2s infinite;
    }

        .arrow span:nth-child(2) {
            animation-delay: -0.2s;
        }

        .arrow span:nth-child(3) {
            animation-delay: -0.4s;
        }

@keyframes animate {
    0% {
        opacity: 0;
        transform: rotate(225deg) translate(-20px, -20px);
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: rotate(225deg) translate(20px, 20px);
    }
}

.service-banner {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: auto;
}

.btn-tertiary img {
    width: 2vh;
}

.overlay-video {
    position: absolute;
    right: 15px;
    top: 15px;
    padding: 1px 7px 4px 8px;
    background: #0000007a;
    border-radius: 10px;
}

.hidden-video {
    display: none;
}

.overlay-video img {
    width: 20px;
    height: auto;
}

table,
.pagination button.page-link {
    font-size: 1.73vh;
}

.pt-20 {
    padding-top: 2vh;
}

.btn-action {
    height: 3.8vh !important;
    width: 3.8vh !important;
}

.events .pagination button,
.events .pagination li {
    padding: 0 !important;
    height: 3.8vh !important;
    width: 3.8vh !important;
}

.table > :not(caption) > * > * {
    padding: 0.8vh 0.8vh;
}

.form-control,
.css-1jqq78o-placeholder {
    font-size: 1.74vh;
}

.css-1xc3v61-indicatorContainer {
    padding: 1.4vh;
}

.card-mid {
    height: 78vh;
    overflow: scroll;
    overflow-x: hidden;
    background: #ffffff;
    overflow-y: auto;
    padding: 1.7vh;
    border-bottom-left-radius: 4.3vh !important;
    border-top-right-radius: 4.3vh !important;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
}

.solution-control .carousel-indicators {
    margin-bottom: 0 !important;
}

.solution-page .nav-tabs .nav-link.active,
.solution-page .nav-tabs .nav-item.show .nav-link {
    color: var(--green);
    background-color: transparent;
}

.solution-page .nav-tabs .nav-link {
    border: 0;
}

.solution-page .nav-link.active:before {
    width: 75%;
    bottom: 0;
}

.solution-indicators [data-bs-target] {
    width: 1.1vh;
    height: 1.1vh;
    background-color: #b7b7b7;
    border-radius: 25px;
    border-top: 0px solid transparent;
    border-bottom: 0px solid transparent;
    border-left: 0px solid transparent;
    border-right: 0px solid transparent;
}

.solution-indicators .active {
    background-color: var(--green);
    width: 1.5vh;
    height: 1.5vh;
}

.solution-controls .carousel-control-prev {
    left: 0;
    /* border: 1px solid var(--green);
     border-radius: 50%;
     width: 46px !important; */
}

.solution-controls .carousel-control-next {
    right: 0;
    /* border: 1px solid var(--green);
     border-radius: 50%;
     width: 46px !important; */
}

.solution-controls .carousel-control-prev-icon,
.solution-controls .carousel-control-next-icon {
    width: 1rem;
    height: 1rem;
}


.solution-page .carousel-outer {
    position: relative;
    width: 100%;
}

.jc-c {
    justify-content: center;
}

.p-absolute {
    position: absolute;
}

.p-relative {
    position: relative;
}

.edit-profile {
    top: -5px;
    right: -5px;
    background: #ffffff;
    padding: 3px;
    width: 30px;
    height: 30px;
    text-align: center;
    /* width: 43px; */
    border-radius: 50px;
    box-shadow: rgb(0 0 0 / 32%) -1px 5px 14px 0px;
}

    .edit-profile img {
        width: 15px;
    }

.common-card {
    border-top-left-radius: 0;
    border-top-right-radius: 4.3vh;
    border-bottom-left-radius: 4.3vh;
    border-bottom-right-radius: 0;
    background: #ffffff;
    border: 0 !important;
    padding: 0;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.common-card .card-header {
    border-bottom: 1px solid #ebebeb !important;
    border-radius: 0 !important;
    background: #ffffff;
    padding: 1.5vh !important;
    border-top-right-radius: 4.3vh !important;
}



.common-card .card-body {
    padding: 1.5vh;
}

.video-modal {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
}

    .video-modal.modal.show {
        background: #000000d6;
    }

    .video-modal .modal-dialog {
        width: fit-content;
        max-width: fit-content;
    }

    .video-modal .modal-content {
        background: transparent;
        border: 0;
        box-shadow: none;
    }

    .video-modal .modal-header {
        background: transparent !important;
        border-bottom: 0;
        padding: 0;
        text-align: right;
        display: flex;
        justify-content: flex-end;
    }

    .video-modal button.close {
        background: transparent;
        padding: 0;
    }

    .video-modal .modal-body {
        width: 100%;
        max-width: 100%;
    }

.gallery-grid {
    display: flex;
    justify-content: space-between;
}

.gallery-column {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.gallery-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1px;
}

.gallery-image {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}

.gallery-column:nth-child(2) .gallery-image-container {
    padding-left: 1px;
    padding-right: 1px;
}

.rupees-icon {
    position: absolute;
    left: 25px;
    top: 20px;
    bottom: 0;
    z-index: 99;
    width: 10px;
}

.btn-grey {
    background: #f4f4f4;
    padding: 1.1vh 2vh;
}

.rupees-icon2 {
    width: 9px;
}

.mr-3 {
    margin-right: 3px;
}

.btn-grey.active,
.btn-grey:focus,
.btn-grey:visited,
:not(.btn-grey) + .btn:active {
    color: #fff !important;
}


    .btn-grey.active img {
        filter: brightness(0) invert(1);
    }

.btn-grey:hover {
    background: var(--green);
    color: #fff;
}

    .btn-grey:hover img {
        filter: brightness(0) invert(1);
    }

.pr-15 {
    padding-left: 0;
    padding-right: 15px;
}

td strong img {
    filter: brightness(0) invert(1);
}

.res-container2 {
    width: 100%;
    /* max-width: 85%; */
    max-width: 100%;
    margin: 11vh auto;
}

.event-banner {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    text-align: center;
    padding: 40px 0;
}

    .event-banner::after {
        content: "";
        /* Required for pseudo-elements */
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        /* Adjust the alpha (0.5) for transparency */
    }

.c-icons {
    width: 17px;
    height: auto;
}

.cust-e .c-icons {
    filter: brightness(0) saturate(100%) invert(74%) sepia(12%) saturate(4364%) hue-rotate(36deg) brightness(95%) contrast(64%);
}

.event-banner span {
    color: #ffffff;
    z-index: 1;
    position: relative;
    margin: auto;
    text-align: center;
}

.event-banner h1 {
    color: #ffffff;
    z-index: 1;
    position: relative;
    margin: auto;
    text-align: center;
    line-height: 4vh;
}

.jc-sa {
    justify-content: space-around;
}

img.action-img {
    width: auto;
    height: 20px;
}

    img.action-img.del {
        filter: brightness(0) saturate(100%) invert(19%) sepia(100%) saturate(2641%) hue-rotate(339deg) brightness(86%) contrast(85%);
    }

.event-footer button {
    border: 0;
    outline: 0;
    padding: 0;
    margin: 0;
    background: transparent;
}

.event-footer {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 10px;
    border-top-left-radius: 35px;
    width: 100px;
}

.events .pagination {
    margin: auto;
    width: fit-content;
    padding-right: 15px;
    margin: 15px auto 15px;
}

.btn-action {
    width: 35px;
    height: 35px;
    padding: 0;
}

.events .pagination button,
.events .pagination li {
    background: transparent;
    border: 0;
    padding: 5px 15px;
}

    .events .pagination li button {
        color: #000;
    }

    .events .pagination li.active button {
        color: #ffffff;
    }

    .events .pagination button.active,
    .events .pagination li.active {
        color: var(--white) !important;
        background: var(--green);
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
    }

.pagination button.page-link {
    padding: 0 !important;
}

.f-right {
    float: right;
}

.btn-sm {
    padding: 0.4vh 2vh;
}

.btn-silver {
    background: #eaeaea;
}

.jc-e {
    justify-content: end;
}

/* common */
.cust-ribbon {
    width: 110px;
    height: 110px;
    overflow: hidden;
    position: absolute;
    z-index: 3;
}

    .cust-ribbon::before,
    .cust-ribbon::after {
        position: absolute;
        z-index: -1;
        content: '';
        display: block;
        border: 5px solid #61a300;
    }

    .cust-ribbon span {
        position: absolute;
        display: block;
        width: 225px;
        padding: 4px 0;
        background-color: var(--green);
        box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
        color: #fff;
        text-transform: capitalize;
        text-align: center;
    }

/* top left*/
.ribbon-top-left {
    top: -10px;
    left: -10px;
}

    .ribbon-top-left::before,
    .ribbon-top-left::after {
        border-top-color: transparent;
        border-left-color: transparent;
    }

    .ribbon-top-left::before {
        top: 0;
        right: 0;
    }

    .ribbon-top-left::after {
        bottom: 0;
        left: 0;
    }

    .ribbon-top-left span {
        right: -45px;
        top: 29px;
        transform: rotate(-45deg);
    }

.o-hidden {
    overflow: hidden;
}

.ml-20 {
    margin-left: 20px;
}

.single-form {
    overflow: hidden;
    overflow-y: scroll;
    height: 72vh;
}

.single-decs .card-body {
    height: 37vh;
    overflow: hidden;
    overflow-y: scroll;
}

.events-content .nav-tabs .nav-link.active,
.events-content .nav-tabs .nav-link:focus,
.events-content .nav-tabs .nav-link:hover,
.events-content .nav-tabs .nav-link,
.events-content .nav-tabs .nav-item.show .nav-link {
    border: 0;
    color: var(--black);
    margin: 0;
    margin-bottom: 0 !important;
}

.event-gallery-content {
    overflow: hidden;
    overflow-y: scroll;
    height: 27vh;
}

.gallery-img {
    width: 100%;
    max-width: 100%;
    height: 15vh;
    border-radius: 5px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 3px;
}

.gallery-image2 {
    width: 100%;
    height: 25vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}

.gallery-image4 {
    width: 100%;
    height: 22vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}

.gallery-outer4 {
    width: 100%;
    max-width: 50%;
    padding-left: 1px;
    padding-right: 1px;
    margin-bottom: 2px;
}

.gallery-image3 {
    width: 100%;
    height: auto;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}

    .gallery-image3 video {
        width: 90%;
        height: 22vh;
    }

.gallery-outer2 {
    width: 100%;
    max-width: 25%;
    padding-left: 1px;
    padding-right: 1px;
    margin-bottom: 2px;
}

.gallery-text {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
}

.gallery-outer {
    /* margin-right: 3px; */
    width: 100%;
    max-width: 25%;
    height: 15vh;
    padding-right: 3px;
    padding-left: 0;
    margin-bottom: 3px;
}

    .gallery-outer:last-child {
        padding-right: 0;
    }

video {
    width: 100%;
}

h4 a {
    font-size: 2.55vh !important;
}

.filter-green {
    filter: brightness(0) saturate(100%) invert(74%) sepia(12%) saturate(4364%) hue-rotate(36deg) brightness(95%) contrast(64%);
}

.filter-white {
    filter: brightness(0) invert(1);
}

.event-details1 {
    height: 57vh;
    overflow: hidden;
    overflow-y: scroll;
}

.hand {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 170px;
    height: auto;
}

/* Gallery.css */
.image-headline {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0;
    /* Initial height is 0 */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
    color: white;
    text-align: center;
    padding: 0;
    transition: height 0.3s, background 0.3s;
    overflow: hidden;
}

.gallery-image2:hover .image-headline {
    align-content: end;
    flex-wrap: wrap;
    justify-content: center;
    display: flex;
    height: 30%;
    background: linear-gradient(to bottom, rgb(0 0 0 / 0%), rgb(0 0 0 / 48%), rgb(0 0 0 / 67%));
}

.label-icon {
    width: 15px;
    height: auto;
}

.image-modal .modal-dialog {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    margin: 0;
    border-radius: 0;
}

.image-modal .modal-body {
    padding: 0;
    border-radius: 0;
}

.image-modal .modal-content {
    border-radius: 0 !important;
    width: 100%;
    height: 100vh;
}

.image-modal .row {
    height: 100vh;
}

.image-modal .left-con {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    background: #000000;
}

.img-width {
    width: 100%;
    height: auto;
}

.img-height {
    width: auto;
    height: 100vh;
}

.p-30 {
    padding: 30px;
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-collapse {
        justify-content: center;
    }
}

.admin-chart canvas {
    height: auto;
    max-height: 40vh;
}

.admin-content .card {
    height: 40vh;
}

.image-modal button.close {
    background: var(--green);
    border: 0;
    width: 50px;
    height: 50px;
    padding: 0px 0 10px 10px;
    position: absolute;
    top: 0;
    right: 0;
    border-bottom-left-radius: 50px;
}

.image-modal .right-con {
    display: flow;
    align-content: center;
    flex-wrap: wrap;
    display: flex;
}

.image-modal.video-modal video {
    width: auto;
    height: 100vh;
}

.video-modal .col-12 {
    background: #111011;
    text-align: center;
}

.video-bg {
    width: 100%;
    height: 20vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
}

    .video-bg::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        /* Adjust the opacity as needed */
        z-index: 1;
        /* Ensure it's above the video content */
    }

    .video-bg button {
        z-index: 1;
        position: relative;
        border: 0;
        background: #ffffff63;
        padding: 20px;
        border-radius: 50%;
    }

        .video-bg button img {
            width: 30px;
        }

.btn-transparent:hover,
.btn-transparent:active,
.btn-transparent:focus {
    background: var(--green);
}

.hide {
    display: none;
}

.don-card {
    border-top-right-radius: 2.5vh !important;
    border-bottom-left-radius: 2.5vh !important;
    border-bottom-right-radius: 2.5vh !important;
    border-top-left-radius: 0vh !important;
}

.don-silver-bg {
    background-color: #ffffff;
    border-top-right-radius: 2.5vh !important;
    border-top-left-radius: 0vh !important;
    border-bottom-right-radius: 2.5vh !important;
    border-bottom-left-radius: 2.5vh !important;
    border: 1px solid var(--green);
}

.btn-transparent {
    background-color: transparent;
    border: 1px solid transparent;
}

    .btn-transparent.btn-primary {
        background: var(--green) !important;
        border-color: var(--green) !important;
        color: var(--white) !important;
    }

    .btn-transparent.btn-light:hover {
        background-color: transparent !important;
        border: 1px solid transparent !important;
    }

.donation-form .common-card {
    overflow: visible !important;
}

.css-1p3m7a8-multiValue {
    background-color: white !important;
    border: 1px solid var(--green);
    border-radius: 25px !important;
    padding: 0px 4px;
}

.css-12a83d4-MultiValueRemove:hover {
    background-color: var(--green) !important;
    color: #ffffff !important;
    border-radius: 25px !important;
}

.css-1p3m7a8-multiValue {
    flex-wrap: wrap;
    align-items: center;
}

.css-12a83d4-MultiValueRemove {
    width: 20px;
    height: 20px;
}

    .css-12a83d4-MultiValueRemove:hover .css-tj5bde-Svg {
        color: #ffffff !important;
    }

.css-tj5bde-Svg {
    color: var(--green);
}

.css-13cymwt-control,
.css-t3ipsp-control {
    height: auto;
    min-height: 5.8vh !important;
    background-color: hsl(0deg 0% 95.69%) !important;
    border-color: hsl(0deg 0% 95.69%) !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 25px !important;
    border-bottom-left-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
}

.css-b62m3t-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.css-3w2yfm-ValueContainer {
    padding: 10.57px 10px !important;
}

.btn-static {
    background: #d7d7d7 !important;
}

.symbol-icon {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    position: absolute;
    top: 15px;
    left: 20px;
    z-index: 1;
}

.admin-service .nav-link.active:before {
    content: '';
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    top: 0;
    left: auto;
    /* margin-left: 10px; */
    right: 0;
    height: 4px;
    background-color: var(--green);
    /* margin: 0 10px; */
    width: 20px;
}

.admin-service .nav-link {
    width: fit-content;
    padding: 1.1vh 4vh 1.1vh 2vh !important;
}

.text-danger {
    font-size: 1.3vh;
}

label ~ .form-control ~ .text-danger ~ {
    margin-bottom: 0;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
    margin-bottom: 0.8vh;
}

.events .nav-tabs .nav-link {
    font-size: 1.73vh !important;
}

.bottom-image {
    width: 55vh;
}

@media (min-width: 1200px) {

    h2,
    .h2 {
        font-size: 3.5vh;
    }
}

.donate-btn {
    display: none;
}

.card-header.d-flex.jc-sb.pb-0 {
    padding-bottom: 0 !important;
}

.service-bg {
    height: 30vh;
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 24px;
    /* Add more styles as needed */
}

.location-city {
    cursor: pointer;
}

.location-address {
    display: none;
}

    .location-address.visible {
        display: block !important;
    }

.city-head {
    border: 1px solid #E5E5E5;
    padding: 10px;
    border-radius: 10px;
    width: 100%;
    margin-bottom: 7px;
}

.location-city.active {
    font-weight: bold;
}

.dropdown.sm-s.sm-h.mr-5 ul.dropdown-menu.show {
    right: 0;
    left: auto;
}

.d-h {
    display: block;
}

.d-s {
    display: none;
}

.d-hide {
    display: block;
}

.mob-nav {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 20px 0px;
}

.hp-main {
    display: flex;
    flex-direction: column;
    height: -webkit-fill-available;
    padding: 10vh 0 0vh 0;
}

.hp-child1 {
    flex: 0 1 auto;
    /* Fixed size at the top and bottom */
}

.hp-child2 {
    flex: 1 1 auto;
    /* Take up remaining space */
    display: flex;
    align-items: center;
    justify-content: left;
}

.hp-child3 {
    flex: 0 1 auto;
    /* Fixed size at the top and bottom */
}

@media only screen and (max-width: 991px) {
    .vol-3 {
        order: 3;
    }

    .vol-2 {
        order: 2;
    }

    .vol-1 {
        order: 1;
    }

    .donate-btn {
        display: block;
    }

    .d-h {
        display: none;
    }

    .d-s {
        display: block;
    }

    .sm-o-1 {
        order: 1;
    }

    .sm-o-2 {
        order: 2;
    }

    .sm-s {
        display: block;
    }

    .d-hide {
        display: none;
    }

    .donation2 .col-8.d-flex.jc-e {
        display: grid !important;
        justify-content: normal;
    }

    .customer-navbar .nav-link {
        color: inherit;
    }

    .customer-navbar .navbar-collapse {
        z-index: 9;
        position: absolute;
        width: 93%;
        border: 1px solid #ebebeb !important;
        margin: auto;
        border-radius: 10px;
        left: 0px;
        top: 10vh;
        right: 0px;
    }

    a.navbar-brand img {
        max-width: 100px;
    }

    h4.head.mr-20 {
        display: none;
    }

    .navbar-nav .nav-link.active {
        color: var(--green) !important;
    }

    .nav-link.active:before {
        display: none;
    }

    .bg-light {
        background-color: #ffffff !important;
    }

    .cust-home-banner {
        width: 100% !important;
    }

    body {
        height: auto;
        overflow: auto;
    }

    #root {
        height: auto;
        overflow: auto;
    }

    .navbar-collapse {
        background: #ffffff !important;
    }

    .bg-top,
    .bg-bottom {
        height: auto;
    }

    .about-mission,
    .about-content {
        min-width: 100%;
        width: 100%;
        max-width: 100%;
    }

    .about-content {
        margin-bottom: 50px;
    }

    .bg-bottom .res-container .row {
        padding: 50px 0;
    }

    .bg-top .res-container .row {
        padding: 120px 0 50px 0;
    }

    .img-height {
        width: auto;
        height: 100%;
    }

    .image-modal .left-con {
        height: 70%;
        width: 100%;
    }

    .profile-div {
        position: absolute;
        top: 15px;
        right: 15px;
    }

    .image-modal .right-con {
        height: 30vh;
        position: absolute;
        bottom: 0;
        background: white;
        width: 100%;
        display: grid;
    }

    .image-modal .row {
        height: 100%;
        display: grid;
    }
}

@media only screen and (max-width: 1920px) {
    .res-container {
        /* max-width: 1632px; */
        max-width: 100%;
    }
}

@media only screen and (max-width: 1632px) {
    body {
        height: 100%;
        overflow-y: auto;
    }



    .res-container {
        /* max-width: 1280px; */
        max-width: 100%;
    }
}

@media only screen and (min-width: 1921px) {
    .res-container {
        /* max-width: 85%; */
        max-width: 100%;
    }
}

@media (min-width:1280px) {
    .home-top .tab-content {
        height: inherit;
    }
}

@media (max-width: 414px) {
    .home-top .col-40 img {
        padding: 20px !important;
    }

    .pm .modal-dialog {
        width: 100% !important;
    }

    .pm .modal-content {
        height: 100vh;
    }

    .col-8 {
        width: 100%;
    }

    /* .events,
    .events-single,
    .solution-page {
        padding-top: 15vh !important;
    } */
}

@media (max-width: 1279px) {
    #root {
        overflow: unset;
    }

    .mobileone .home-tab .btn-link {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .logo img {
        width: 13vh;
    }

    .arrow {
        display: none;
    }

    .home-page .res-container {
        display: grid;
    }


    .home-tab li {
        flex: none;
    }

    .home-tab .btn-link {
        display: flex;
    }

    .home-tab .nav-link img {
        height: 50px;
        margin-bottom: 0px;
        margin-right: 10px;
        padding: 10px 0;
    }

    .mobileone .nav-item:last-child {
        width: 60%;
    }

    .mobileone .btn-link h4 {
        padding: 0;
        margin: 0;
    }

    .mobileone .btn-link {
        width: 100%;
    }

    .mobileone .nav-item {
        width: 50%;
    }

    .home-page .res-container {
        padding-top: 15px;
    }

    .home-top h1 {
        font-size: 5vh;
        line-height: 5vh;
    }

    .mobileone .chart .nav-item:last-child {
        width: auto;
    }

    .mobileone .chart .nav-item {
        width: auto;
    }

    .home-page .chart {
        margin-bottom: 20px;
    }

    .home-page .home-bottom {
        margin-bottom: 30px;
    }

    .home-tab p {
        display: none;
    }

    .home-tab {
        display: flex !important;
        flex-wrap: wrap;
    }

    .home-page .home-top {
        order: 2;
    }

    .home-page .home-bottom {
        order: 1;
    }

    .mobiletwo h4 {
        display: none;
    }

    .mobiletwo .nav-item {
        width: auto;
    }

    .mobiletwo .home-tab .nav-link img {
        height: 30px;
        margin-bottom: 0px;
        margin-right: 0;
        padding: 0;
    }

    .mobiletwo .nav-item:last-child {
        width: auto;
    }

    .mobiletwo .home-tab li {
        padding: 0 5px;
    }

    .mobiletwo .home-tab.nav-tabs .nav-link {
        padding: 13px !important;
    }

    .mobiletwo .home-bottom {
        margin-bottom: 20px;
    }

    .cust-underline:after {
        top: 2vh;
    }
}

.btn.w-100.btn-transparent.btn-light {
    padding: 1.1vh 2.1vh !important;
}

@media (max-width: 1279px) {
    .about-container {
        margin-bottom: 20px;
    }



    .sm-right {
        position: relative;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
    }

        .sm-right div {
            display: flex !important;
            padding: 0 20%;
        }

        .sm-right a {
            margin: auto;
        }

    .about-content p {
        text-align: justify;
    }

    .about .res-container {
        margin: 0 !important;
    }

        .about .res-container .row {
            padding: 0 !important;
        }

    .event-head .col.d-flex.jc-sb.plr-20 {
        display: grid !important;
    }

    .contact-form {
        margin-bottom: 40px;
    }

    .events .col-4.card-mid .d-grid.text-center {
        width: 100%;
    }

    .events .col-4.card-mid .card-mid {
        width: 100%;
    }

    .bottom-image2,
    .bg-left.donation-left,
    .login .bg-left,
    .contact .middle-image {
        display: none;
    }

    .donation-right,
    .login .bg-right,
    .register-form,
    .contact .bg-left,
    .contact .bg-right,
    .contact-form,
    .col-4.about-mission,
    .col-8.about-content,
    .home-page .col-30,
    .home-page .col-40 {
        max-width: 100% !important;
        min-width: 100% !important;
    }

    .donation-form,
    .col-3.gallery-outer2 {
        max-width: 100%;
    }

    .header,
    .admin-header {
        position: relative;
    }

    .login .row,
    .events .row,
    .contact .row,
    .about .row {
        display: grid;
    }

    .events,
    .events-single,
    .solution-page {
        padding-top: 0;
    }

    .col-3,
    .col-5,
    .col-4,
    .col-6,
    .col-8,
    .col-md-4 {
        width: 100%;
    }

    .sm-pt-10 {
        padding-top: 10.8vh;
    }

    .events .card-mid,
    .about .bg-top,
    .about .bg-bottom,
    .home-bottom,
    .home-top {
        height: auto;
    }

    .contact .background-image,
    .about .bg-bottom {
        height: auto;
        padding: 20px;
        position: relative;
    }

    .contact .bg-left {
        padding: 0 !important;
    }

    /* .about {
        overflow-y: scroll;
    } */

    .cust-home-banner {
        position: relative;
        height: auto;
        width: 50%;
    }

    .home-tab {
        display: grid;
    }

    .home-top .col-40 img {
        padding: 0 15%;
        top: 0;
    }
}

.mob-nav .navbar-toggler {
    border: var(--bs-border-width) solid rgb(255 255 255 / 100%);
}

.mob-nav .navbar-toggler-icon {
    filter: brightness(0) saturate(100%) invert(100%) sepia(98%);
}

.customer-navbar .collapse.show .nav-link {
    color: #000 !important;
}

.dropdown-menu-right {
    right: 0;
    left: auto !important;
}

.symbol-icon {
    z-index: 6;
}


.otp-input-container {
    display: flex;
    justify-content: center;
}

    .otp-input-container input {
        width: 40px;
        /* Adjust width as needed */
        height: 40px;
        /* Adjust height as needed */
        margin: 0 5px;
        /* Adjust margin as needed */
        text-align: center;
        font-size: 16px;
        border: 1px solid #ccc;
        /* Border color */
        border-radius: 4px;
        /* Adjust border radius as needed */
    }

        /* Style for focused input field */
        .otp-input-container input:focus {
            outline: none;
            border-color: #007bff;
            /* Adjust focus border color as needed */
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
            /* Adjust focus box shadow as needed */
        }

.loader-main {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ffffff;
    margin: auto;
    z-index: 99999;
}

.loader-sub {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    /* Ensure it sits above everything else */
}